<template>
  <div>
    <v-container fluid>
      <v-widget title="Produtos">
        <template v-slot:toolbar>
          <v-container fluid>
            <v-row dense>
              <v-col cols="10" sm="5">
                <v-text-field label="Buscar produto" filled flat single-line hide-details dense clearable solo v-model="busca" @click:clear="limparBusca" @keyup.enter="filtrar($event)" prepend-inner-icon="search" background-color="grey lighten-3" />
              </v-col>

              <v-col cols="2" sm="7" class="text-right">
                <v-btn v-if="!isSm" depressed color="primary" @click="novo"> NOVO </v-btn>
                <v-btn v-else depressed fab small color="primary" @click="novo">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template v-slot:widget-content>
          <v-data-table :options.sync="options" :server-items-length="10" :headers="headers" :items="produtos" :loading="carregando" mobile-breakpoint="0">
            <template v-slot:item="props">
              <tr>
                <td class="text-left">{{ props.item.codigo }}</td>
                <td>{{ props.item.descricao }}</td>
                <td class="text-right">{{ props.item.estoque | formatarValor }}</td>
                <td class="text-right">{{ props.item.valor | formatarValor }}</td>
                <td class="text-center">
                  <v-menu top right transition="slide-x-transition" @click.native.stop="">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" data-cy="menu-acoes">more_vert</v-icon>
                    </template>
                    <v-list dense>
                      <v-list-item @click="ajustarEstoque(props.item)" data-cy="editar">
                        <v-list-item-icon><v-icon>mdi-archive-alert</v-icon></v-list-item-icon>
                        <v-list-item-title>Ajustar Estoque</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item @click="editar(props.item)" data-cy="editar">
                        <v-list-item-icon><v-icon>edit</v-icon></v-list-item-icon>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item @click="remover(props.item)" data-cy="remover">
                        <v-list-item-icon><v-icon>delete</v-icon></v-list-item-icon>
                        <v-list-item-title>Remover</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item @click="abrirIndices(props.item)" data-cy="remover" v-if="props.item.ippt == 'P'">
                        <v-list-item-icon><v-icon>mdi-format-list-bulleted-square</v-icon></v-list-item-icon>
                        <v-list-item-title>Índices Técnicos</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-widget>

      <dialog-confirmacao ref="dialogConfirmacao" />
    </v-container>

    <indices-tecnicos ref="indice" :produto="produto" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import constantes from '@/util/constantes';
import IndicesTecnicos from './IndicesTecnicos.vue';
import DialogConfirmacao from '../../components/dialogs/DialogConfirmacao.vue';

export default {
  components: {
    IndicesTecnicos,
    DialogConfirmacao,
  },
  data() {
    return {
      headers: [
        { text: 'Código', value: 'codigo', sortable: false, align: 'left', width: '180px' },
        { text: 'Descrição', value: 'descricao', sortable: false, width: '65%' },
        { text: 'Estoque', value: 'estoque', align: 'right', sortable: false, width: '10%' },
        { text: 'Valor', value: 'valor', align: 'right', sortable: false, width: '10%' },
        { text: '', value: 'name', align: 'center', sortable: false, width: '32px' },
      ],
      constantes: constantes,
      busca: '',
      options: {
        itemsPerPage: 10,
        page: 1,
      },
    };
  },

  watch: {
    options: {
      handler() {
        this.listar();
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('produto', ['produtos', 'carregando', 'produto']),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    computedOptions() {
      return { itemsPerPage: this.options.itemsPerPage, page: this.options.page, busca: (this.busca && this.busca.trim()) || null };
    },
  },

  methods: {
    novo() {
      this.$store.dispatch('produto/novo', this.$store.state.autenticacao.session.empresa.configuracaoTributaria.regimeTributario);
      this.$router.push('/cadastros/produtos/novo');
    },

    editar(produto) {
      this.$store.commit('produto/setState', { produto });
      this.$router.push({ name: 'formularioProdutoEditar' });
    },

    abrirIndices(produto) {
      this.produto = produto;
      this.$refs.indice.abrir();
    },

    listar() {
      this.$store.dispatch('produto/listar', this.computedOptions);
    },

    limparBusca() {
      this.busca = null;
      this.listar();
    },

    filtrar(event) {
      this.listar();
      this.$nextTick(() => event.target.select());
    },

    remover(item) {
      this.$refs.dialogConfirmacao.open('Remover', 'Tem certeza que deseja remover?').then((confirmado) => {
        if (confirmado) {
          this.$store.dispatch('produto/remover', item._id);
        }
      });
    },

    ajustarEstoque(produto_selecionado) {
      this.$store.commit('produto/setItemAtual', produto_selecionado);
      this.$refs.ajuste_estoque.abrir();
    },
  },
};
</script>
